g[id*="Table_"] rect:first-child {
	fill: #efefef;
}

.rectNew {
	stroke: white;
	stroke-linejoin: round;
	border-radius: 2px;
	width: 5px;
	height: 17px;
}