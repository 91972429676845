.container {
    display: grid;
    place-items: center;
    min-height: 100vh;
}

.schemes {
    display: grid;
    overflow: scroll;

    max-height: 350px;
    max-width: 562px;
    place-items: center;
}

.schemes svg {
    width: 100%;
    height: auto;
}
